<template>
  <div class="p-4 bg-white rounded-lg">
    <template v-if="isAdmin"
      ><DataTable
        :table_state="table_state"
        :store_names="store_names"
        :addComponent="componentForAdding"
        :editComponent="componentForEditing"
        :delete_url="delete_url"
      ></DataTable
    ></template>
    <template v-else>
      <TrackingPageClient />
    </template>
  </div>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import AddItem from "@/own/components/shipping/TrackingPageClient/AddItem";
import EditItem from "@/own/components/shipping/TrackingPageClient/EditItem";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/trackingPage.module";
import DataTable from "@/own/components/DataTable";
import TrackingPageClient from "@/own/components/shipping/TrackingPageClient/TrackingPageClient";

export default {
  name: "TrackingPage",
  components: { DataTable, TrackingPageClient },
  data: () => ({
    componentForEditing: EditItem,
    componentForAdding: AddItem,
    delete_url: `${process.env.VUE_APP_BASE_URL}/shipping/trackingPage/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getTRACKINGPAGETableFilters",
      getTableState: "getTRACKINGPAGETableState",
      getTableData: "getTRACKINGPAGETableData",
      getTableHeaders: "getTRACKINGPAGETableHeaders",
      getTableProperties: "getTRACKINGPAGETableProperties",
      getTableExportUrl: "getTRACKINGPAGEExportUrl",
      getTablePermissions: "getTRACKINGPAGETablePermissions",
    },
    isAdmin: null,
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.isAdmin = !this.$store.getters.currentUser.data.is_client;
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
