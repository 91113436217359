<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1350"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add New Item</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body mt-4 h-580px scroll-y mx-5 pt-0 pb-7">
        <v-tabs v-model="tabs" mobile-breakpoint="300">
          <v-tab>
            <h3 class="font-weight-bolder ma-0 font-size-h6 poppins">Main</h3>
          </v-tab>
          <v-tab>
            <h3 class="font-weight-bolder ma-0 font-size-h6 poppins">
              Status
            </h3></v-tab
          >
        </v-tabs>

        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-card flat>
              <v-card-text class="px-0 mt-4">
                <div class="row px-4">
                  <div class="col-12 col-lg-6 pa-0 pa-sm-auto">
                    <div class="form-group row d-flex align-center">
                      <label
                        class="col-3 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                      >
                        <span class="required">Client</span>
                      </label>
                      <div class="col-9 col-sm-8">
                        <v-autocomplete
                          v-model="clientId"
                          class="h-40px border-0 form-control placeholder-dark-50 custom-autocomplete"
                          placeholder="Select Client"
                          :items="serverData"
                          item-text="text"
                          item-value="index"
                          color="#3f4254"
                          hide-details
                          clearable
                          dense
                          outlined
                        ></v-autocomplete>
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>

                    <div class="form-group row d-flex align-center">
                      <label
                        class="col-3 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                      >
                        <span class="required">Domain Prod</span>
                      </label>
                      <div class="col-9 col-sm-8">
                        <input
                          type="text"
                          v-model="domainProd"
                          class="form-control h-40px"
                          placeholder="Domain Prod"
                        />
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>

                    <div class="form-group row d-flex align-center">
                      <label
                        class="col-3 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                      >
                        <span class="required">Domain Test</span>
                      </label>
                      <div class="col-9 col-sm-8">
                        <input
                          type="text"
                          v-model="domainTest"
                          class="form-control h-40px"
                          placeholder="Domain Test"
                        />
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>
                    <div class="form-group row d-flex align-center">
                      <label
                        class="col-3 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                      >
                        <span class="required">Gradient 1</span>
                      </label>
                      <div class="col-9 col-sm-8">
                        <input
                          type="text"
                          v-model="color_1"
                          class="form-control h-40px"
                          placeholder="Click to add color"
                          @click="addColor1"
                        />
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>

                    <div class="form-group row d-flex align-center">
                      <label
                        class="col-3 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                      >
                        <span class="required">Gradient 2</span>
                      </label>
                      <div class="col-9 col-sm-8">
                        <input
                          type="text"
                          v-model="color_2"
                          class="form-control h-40px"
                          placeholder="Click to add color"
                          @click="addColor2"
                        />
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>

                    <div class="form-group row d-flex align-center">
                      <label
                        class="col-3 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                      >
                        <span class="required">Background color</span>
                      </label>
                      <div class="col-9 col-sm-8">
                        <input
                          type="text"
                          v-model="color_3"
                          class="form-control h-40px"
                          placeholder="Click to add color"
                          @click="addColor3"
                        />
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>

                    <div class="form-group row d-flex align-center">
                      <label
                        class="col-3 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                      >
                        <span class="required">Text Color 1</span>
                      </label>
                      <div class="col-9 col-sm-8">
                        <input
                          type="text"
                          v-model="color_4"
                          class="form-control h-40px"
                          placeholder="Click to add color"
                          @click="addColor4"
                        />
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>

                    <div class="form-group row d-flex align-center">
                      <label
                        class="col-3 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                      >
                        <span class="required">Text Color 2</span>
                      </label>
                      <div class="col-9 col-sm-8">
                        <input
                          type="text"
                          v-model="color_5"
                          class="form-control h-40px"
                          placeholder="Click to add color"
                          @click="addColor5"
                        />
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>
                  </div>

                  <div class="col-12 col-lg-6 mt-6 mt-lg-0 pa-0 pa-sm-auto">
                    <div class="form-group row d-flex align-center">
                      <label
                        class="col-3 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                        ><span class="required">Logo</span></label
                      >
                      <div class="col-9 col-sm-8">
                        <v-file-input
                          v-model="logo"
                          accept="image/png, image/jpeg, image/bmp"
                          outlined
                          placeholder="Click to add Logo"
                          clearable
                          hide-details
                          dense
                        ></v-file-input>
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>

                    <div class="form-group row d-flex align-center">
                      <label
                        class="col-3 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                        ><span>Image</span></label
                      >
                      <div class="col-9 col-sm-8">
                        <v-file-input
                          v-model="image"
                          accept="image/png, image/jpeg, image/bmp"
                          outlined
                          placeholder="Click to add Image"
                          clearable
                          hide-details
                          dense
                        ></v-file-input>
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>

                    <div class="form-group row d-flex align-center">
                      <label
                        class="col-3 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                      >
                        <span class="required">Phone</span>
                      </label>
                      <div class="col-9 col-sm-8">
                        <input
                          type="tel"
                          v-model="phone"
                          class="form-control h-40px"
                          placeholder="Phone"
                        />
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>

                    <div class="form-group row d-flex align-center">
                      <label
                        class="col-3 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                      >
                        <span class="required">Email</span>
                      </label>
                      <div class="col-9 col-sm-8">
                        <input
                          type="email"
                          v-model="email"
                          class="form-control h-40px"
                          placeholder="Email"
                        />
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>

                    <div class="form-group row d-flex align-center">
                      <div class="col-3 pa-0">
                        <v-icon class="ml-1 ml-md-5">mdi-facebook</v-icon>
                      </div>
                      <div class="col-9 col-sm-8">
                        <input
                          type="text"
                          v-model="facebook"
                          class="form-control h-40px"
                          placeholder="Facebook link"
                        />
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>

                    <div class="form-group row d-flex align-center">
                      <div class="col-3 pa-0">
                        <v-icon class="ml-1 ml-md-5">mdi-linkedin</v-icon>
                      </div>
                      <div class="col-9 col-sm-8">
                        <input
                          type="text"
                          v-model="linkedin"
                          class="form-control h-40px"
                          placeholder="LinkedIn link"
                        />
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>

                    <div class="form-group row d-flex align-center">
                      <div class="col-3 pa-0">
                        <v-icon class="ml-1 ml-md-5">mdi-twitter</v-icon>
                      </div>
                      <div class="col-9 col-sm-8">
                        <input
                          type="text"
                          v-model="twitter"
                          class="form-control h-40px"
                          placeholder="Twitter link"
                        />
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>

                    <div class="form-group row d-flex align-center">
                      <div class="col-3 pa-0">
                        <v-icon class="ml-1 ml-md-5">mdi-instagram</v-icon>
                      </div>
                      <div class="col-9 col-sm-8">
                        <input
                          type="text"
                          v-model="instagram"
                          class="form-control h-40px"
                          placeholder="Instagram link"
                        />
                      </div>
                      <div class="offset-sm-1"></div>
                    </div>
                  </div>
                </div>
                <v-dialog
                  v-model="pickerDialog"
                  persistent
                  transition="dialog-bottom-transition"
                  max-width="500"
                >
                  <div
                    class="bg-white h-400px d-flex flex-column align-center justify-space-around"
                  >
                    <v-color-picker
                      v-model="picker"
                      value="#914747FF"
                      elevation="1"
                      dot-size="16"
                      hide-mode-switch
                      mode="hexa"
                      swatches-max-height="200"
                      :width="colorPickerWidth"
                      class="poppins mx-auto"
                    ></v-color-picker>
                    <div class="d-flex align-center justify-center">
                      <button
                        type="reset"
                        @click="pickerDialog = false"
                        class="btn btn-sm btn-light mr-3 px-5 py-3 ls1"
                      >
                        Cancel
                      </button>
                      <button
                        @click="addColors"
                        class="btn btn-sm btn-info px-5 py-3 ls1"
                      >
                        Add Color
                      </button>
                    </div>
                  </div>
                </v-dialog>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text class="px-0 mt-4">
                <div
                  class="row px-4"
                  v-for="status in statuses"
                  :key="status.id"
                >
                  <div class="col-12 col-lg-4">
                    <div class="form-group row d-flex align-center">
                      <label
                        class="col-2 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                      >
                        <span class="required">Title</span>
                      </label>
                      <div class="col-10">
                        <input
                          type="text"
                          v-model="status.title"
                          class="form-control h-40px"
                          placeholder="Order received"
                        />
                      </div>
                      <!--                      <div class="offset-sm-1"></div>-->
                    </div>
                  </div>

                  <div class="col-12 col-lg-8 mt-6 mt-lg-0">
                    <div class="form-group row d-flex align-center">
                      <label
                        class="col-2 mb-0 pa-0 pa-md-3 font-weight-bolder font-size-h6"
                      >
                        <span class="required">Description</span>
                      </label>
                      <div class="col-10">
                        <input
                          type="text"
                          v-model="status.text"
                          class="form-control h-40px"
                          placeholder="Domain Prod"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <!--begin::Actions-->
        <div class="mb-4 mt-16 d-flex align-center justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  components: {},
  data: () => ({
    dialog: false,
    pickerDialog: false,
    logo: null,
    image: null,
    phone: null,
    email: null,
    picker: null,
    facebook: null,
    linkedin: null,
    twitter: null,
    instagram: null,
    color_1: null,
    color_2: null,
    color_3: null,
    color_4: null,
    color_5: null,
    colorIndex: null,
    clientId: null,
    domainProd: null,
    domainTest: null,
    serverData: [],
    statuses: [],
    tabs: null,
  }),
  methods: {
    loadServerData() {
      this.pageLoader(true);
      ApiService.post("/shipping/trackingPage/create")
        .then((response) => {
          this.serverData = response.data.clients;
          this.statuses = response.data.statuses;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    addColors() {
      if (this.colorIndex === 1) this.color_1 = this.picker.hex;
      else if (this.colorIndex === 2) this.color_2 = this.picker.hex;
      else if (this.colorIndex === 3) this.color_3 = this.picker.hex;
      else if (this.colorIndex === 4) this.color_4 = this.picker.hex;
      else this.color_5 = this.picker.hex;
      this.pickerDialog = false;
    },
    addColor1() {
      this.pickerDialog = true;
      this.colorIndex = 1;
    },
    addColor2() {
      this.pickerDialog = true;
      this.colorIndex = 2;
    },
    addColor3() {
      this.pickerDialog = true;
      this.colorIndex = 3;
    },
    addColor4() {
      this.pickerDialog = true;
      this.colorIndex = 4;
    },
    addColor5() {
      this.pickerDialog = true;
      this.colorIndex = 5;
    },
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      else this.loadServerData();
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      let formData = new FormData();
      this.logo && formData.append("logo", this.logo);
      this.image && formData.append("image", this.image);
      formData.append("phone", this.phone);
      formData.append("email", this.email);
      formData.append("color_1", this.color_1);
      formData.append("color_2", this.color_2);
      formData.append("color_3", this.color_3);
      formData.append("color_4", this.color_4);
      formData.append("color_5", this.color_5);
      this.facebook && formData.append("facebook", this.facebook);
      this.linkedin && formData.append("linkedin", this.linkedin);
      this.twitter && formData.append("twitter", this.twitter);
      this.instagram && formData.append("instagram", this.instagram);
      this.clientId && formData.append("client_id", this.clientId);
      formData.append("domain_prod", this.domainProd);
      formData.append("domain_test", this.domainTest);
      formData.append("statuses", JSON.stringify(this.statuses));
      this.pageLoader(true);
      ApiService.post("/shipping/trackingPage/store", formData)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `Record has been successfully added!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.logo = null;
      this.image = null;
      this.phone = null;
      this.email = null;
      this.picker = null;
      this.facebook = null;
      this.linkedin = null;
      this.twitter = null;
      this.instagram = null;
      this.color_1 = null;
      this.color_2 = null;
      this.color_3 = null;
      this.color_4 = null;
      this.color_5 = null;
      this.colorIndex = null;
      this.clientId = null;
      this.domainProd = null;
      this.domainTest = null;
      this.serverData = [];

      this.pickerDialog = false;
    },
  },
  computed: {
    colorPickerWidth: function () {
      let width = 250;
      if (this.$vuetify.breakpoint.xsOnly)
        width = this.$vuetify.breakpoint.width - 80;
      else if (this.$vuetify.breakpoint.smAndUp) width = 450;
      return width;
    },
  },
};
</script>
